
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexg3fEKVF_45FdM8INEsDD1n25Wo_45o9stxMAPDOzLiXFeK4Meta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/index.vue?macro=true";
import { default as indexwP2SE2Bgvti9N0onlWUSqBC0e_451H4gLWyx2XRpYQRw0Meta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startmBoslm_lEQdJTSU0wDai2hQBlS1XS2FClF_DBHu8EaEMeta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as startQTKDrCOnR7txgKaVfyQIIZ4Z5Ju9rYsmlRgySn1lEv8Meta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutgh2grRdIHRJ_izkxRVivryN1wsG49Y0IJxFO01rLptAMeta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as kofCFrRJQmn_45tLvaNlqo_45IIakn6M7JmKmnJsAQ8NsKbHsMeta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmation0I2jSsdmBaLvJiv62g2YR3DMq7Tb3ODSahO6T9ey_45LQMeta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationxzKvpItUVV3SWm4RL26NSGVUHv_LY0dQYgyyf0d_45y14Meta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyeru775aNjrW3DxzByv1C1tDKqHdOrU4_lOpKAx_iRuXlMMeta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyerr8blzKQBkRBdtXr9WoG2LDbadHI_45CbIAE_HPhQaaykwMeta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentkwd7ABU3DfVTYvdyripicAtSDzRlpP2wDZVGFkAMo74Meta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengeZh82Uosrg9Q1_45vagD9zk4Bw4g2_45LFwX_45tly0mJgplGYMeta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentHH8sexRl7_45qd00p5fNESOZT_zbv57yQol3Klfb8ceGUMeta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta } from "/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexg3fEKVF_45FdM8INEsDD1n25Wo_45o9stxMAPDOzLiXFeK4Meta?.name ?? "index",
    path: indexg3fEKVF_45FdM8INEsDD1n25Wo_45o9stxMAPDOzLiXFeK4Meta?.path ?? "/",
    props: indexg3fEKVF_45FdM8INEsDD1n25Wo_45o9stxMAPDOzLiXFeK4Meta?.props ?? false,
    meta: indexg3fEKVF_45FdM8INEsDD1n25Wo_45o9stxMAPDOzLiXFeK4Meta || {},
    alias: indexg3fEKVF_45FdM8INEsDD1n25Wo_45o9stxMAPDOzLiXFeK4Meta?.alias || [],
    redirect: indexg3fEKVF_45FdM8INEsDD1n25Wo_45o9stxMAPDOzLiXFeK4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/index.vue")
  },
  {
    name: indexwP2SE2Bgvti9N0onlWUSqBC0e_451H4gLWyx2XRpYQRw0Meta?.name ?? "lang",
    path: indexwP2SE2Bgvti9N0onlWUSqBC0e_451H4gLWyx2XRpYQRw0Meta?.path ?? "/:lang?",
    props: indexwP2SE2Bgvti9N0onlWUSqBC0e_451H4gLWyx2XRpYQRw0Meta?.props ?? false,
    meta: indexwP2SE2Bgvti9N0onlWUSqBC0e_451H4gLWyx2XRpYQRw0Meta || {},
    alias: indexwP2SE2Bgvti9N0onlWUSqBC0e_451H4gLWyx2XRpYQRw0Meta?.alias || [],
    redirect: indexwP2SE2Bgvti9N0onlWUSqBC0e_451H4gLWyx2XRpYQRw0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startmBoslm_lEQdJTSU0wDai2hQBlS1XS2FClF_DBHu8EaEMeta?.name ?? "lang-voucher-start",
    path: startmBoslm_lEQdJTSU0wDai2hQBlS1XS2FClF_DBHu8EaEMeta?.path ?? "/:lang?/voucher/start",
    props: startmBoslm_lEQdJTSU0wDai2hQBlS1XS2FClF_DBHu8EaEMeta?.props ?? false,
    meta: startmBoslm_lEQdJTSU0wDai2hQBlS1XS2FClF_DBHu8EaEMeta || {},
    alias: startmBoslm_lEQdJTSU0wDai2hQBlS1XS2FClF_DBHu8EaEMeta?.alias || [],
    redirect: startmBoslm_lEQdJTSU0wDai2hQBlS1XS2FClF_DBHu8EaEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: startQTKDrCOnR7txgKaVfyQIIZ4Z5Ju9rYsmlRgySn1lEv8Meta?.name ?? "lang-purchase-start",
    path: startQTKDrCOnR7txgKaVfyQIIZ4Z5Ju9rYsmlRgySn1lEv8Meta?.path ?? "/:lang?/purchase/start",
    props: startQTKDrCOnR7txgKaVfyQIIZ4Z5Ju9rYsmlRgySn1lEv8Meta?.props ?? false,
    meta: startQTKDrCOnR7txgKaVfyQIIZ4Z5Ju9rYsmlRgySn1lEv8Meta || {},
    alias: startQTKDrCOnR7txgKaVfyQIIZ4Z5Ju9rYsmlRgySn1lEv8Meta?.alias || [],
    redirect: startQTKDrCOnR7txgKaVfyQIIZ4Z5Ju9rYsmlRgySn1lEv8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutgh2grRdIHRJ_izkxRVivryN1wsG49Y0IJxFO01rLptAMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutgh2grRdIHRJ_izkxRVivryN1wsG49Y0IJxFO01rLptAMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutgh2grRdIHRJ_izkxRVivryN1wsG49Y0IJxFO01rLptAMeta?.props ?? false,
    meta: checkoutgh2grRdIHRJ_izkxRVivryN1wsG49Y0IJxFO01rLptAMeta || {},
    alias: checkoutgh2grRdIHRJ_izkxRVivryN1wsG49Y0IJxFO01rLptAMeta?.alias || [],
    redirect: checkoutgh2grRdIHRJ_izkxRVivryN1wsG49Y0IJxFO01rLptAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: kofCFrRJQmn_45tLvaNlqo_45IIakn6M7JmKmnJsAQ8NsKbHsMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: kofCFrRJQmn_45tLvaNlqo_45IIakn6M7JmKmnJsAQ8NsKbHsMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: kofCFrRJQmn_45tLvaNlqo_45IIakn6M7JmKmnJsAQ8NsKbHsMeta?.props ?? false,
    meta: kofCFrRJQmn_45tLvaNlqo_45IIakn6M7JmKmnJsAQ8NsKbHsMeta || {},
    alias: kofCFrRJQmn_45tLvaNlqo_45IIakn6M7JmKmnJsAQ8NsKbHsMeta?.alias || [],
    redirect: kofCFrRJQmn_45tLvaNlqo_45IIakn6M7JmKmnJsAQ8NsKbHsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmation0I2jSsdmBaLvJiv62g2YR3DMq7Tb3ODSahO6T9ey_45LQMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmation0I2jSsdmBaLvJiv62g2YR3DMq7Tb3ODSahO6T9ey_45LQMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmation0I2jSsdmBaLvJiv62g2YR3DMq7Tb3ODSahO6T9ey_45LQMeta?.props ?? false,
    meta: confirmation0I2jSsdmBaLvJiv62g2YR3DMq7Tb3ODSahO6T9ey_45LQMeta || {},
    alias: confirmation0I2jSsdmBaLvJiv62g2YR3DMq7Tb3ODSahO6T9ey_45LQMeta?.alias || [],
    redirect: confirmation0I2jSsdmBaLvJiv62g2YR3DMq7Tb3ODSahO6T9ey_45LQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationxzKvpItUVV3SWm4RL26NSGVUHv_LY0dQYgyyf0d_45y14Meta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationxzKvpItUVV3SWm4RL26NSGVUHv_LY0dQYgyyf0d_45y14Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationxzKvpItUVV3SWm4RL26NSGVUHv_LY0dQYgyyf0d_45y14Meta?.props ?? false,
    meta: confirmationxzKvpItUVV3SWm4RL26NSGVUHv_LY0dQYgyyf0d_45y14Meta || {},
    alias: confirmationxzKvpItUVV3SWm4RL26NSGVUHv_LY0dQYgyyf0d_45y14Meta?.alias || [],
    redirect: confirmationxzKvpItUVV3SWm4RL26NSGVUHv_LY0dQYgyyf0d_45y14Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyeru775aNjrW3DxzByv1C1tDKqHdOrU4_lOpKAx_iRuXlMMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyeru775aNjrW3DxzByv1C1tDKqHdOrU4_lOpKAx_iRuXlMMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyeru775aNjrW3DxzByv1C1tDKqHdOrU4_lOpKAx_iRuXlMMeta?.props ?? false,
    meta: buyeru775aNjrW3DxzByv1C1tDKqHdOrU4_lOpKAx_iRuXlMMeta || {},
    alias: buyeru775aNjrW3DxzByv1C1tDKqHdOrU4_lOpKAx_iRuXlMMeta?.alias || [],
    redirect: buyeru775aNjrW3DxzByv1C1tDKqHdOrU4_lOpKAx_iRuXlMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyerr8blzKQBkRBdtXr9WoG2LDbadHI_45CbIAE_HPhQaaykwMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerr8blzKQBkRBdtXr9WoG2LDbadHI_45CbIAE_HPhQaaykwMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerr8blzKQBkRBdtXr9WoG2LDbadHI_45CbIAE_HPhQaaykwMeta?.props ?? false,
    meta: buyerr8blzKQBkRBdtXr9WoG2LDbadHI_45CbIAE_HPhQaaykwMeta || {},
    alias: buyerr8blzKQBkRBdtXr9WoG2LDbadHI_45CbIAE_HPhQaaykwMeta?.alias || [],
    redirect: buyerr8blzKQBkRBdtXr9WoG2LDbadHI_45CbIAE_HPhQaaykwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentkwd7ABU3DfVTYvdyripicAtSDzRlpP2wDZVGFkAMo74Meta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentkwd7ABU3DfVTYvdyripicAtSDzRlpP2wDZVGFkAMo74Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentkwd7ABU3DfVTYvdyripicAtSDzRlpP2wDZVGFkAMo74Meta?.props ?? false,
    meta: paymentkwd7ABU3DfVTYvdyripicAtSDzRlpP2wDZVGFkAMo74Meta || {},
    alias: paymentkwd7ABU3DfVTYvdyripicAtSDzRlpP2wDZVGFkAMo74Meta?.alias || [],
    redirect: paymentkwd7ABU3DfVTYvdyripicAtSDzRlpP2wDZVGFkAMo74Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengeZh82Uosrg9Q1_45vagD9zk4Bw4g2_45LFwX_45tly0mJgplGYMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengeZh82Uosrg9Q1_45vagD9zk4Bw4g2_45LFwX_45tly0mJgplGYMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengeZh82Uosrg9Q1_45vagD9zk4Bw4g2_45LFwX_45tly0mJgplGYMeta?.props ?? false,
    meta: challengeZh82Uosrg9Q1_45vagD9zk4Bw4g2_45LFwX_45tly0mJgplGYMeta || {},
    alias: challengeZh82Uosrg9Q1_45vagD9zk4Bw4g2_45LFwX_45tly0mJgplGYMeta?.alias || [],
    redirect: challengeZh82Uosrg9Q1_45vagD9zk4Bw4g2_45LFwX_45tly0mJgplGYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentHH8sexRl7_45qd00p5fNESOZT_zbv57yQol3Klfb8ceGUMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentHH8sexRl7_45qd00p5fNESOZT_zbv57yQol3Klfb8ceGUMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentHH8sexRl7_45qd00p5fNESOZT_zbv57yQol3Klfb8ceGUMeta?.props ?? false,
    meta: paymentHH8sexRl7_45qd00p5fNESOZT_zbv57yQol3Klfb8ceGUMeta || {},
    alias: paymentHH8sexRl7_45qd00p5fNESOZT_zbv57yQol3Klfb8ceGUMeta?.alias || [],
    redirect: paymentHH8sexRl7_45qd00p5fNESOZT_zbv57yQol3Klfb8ceGUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.path ?? "/spa-balneario/",
    props: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.props ?? false,
    meta: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta || {},
    alias: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.alias || [],
    redirect: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.path ?? "/spa-balneario/malaga/",
    props: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.props ?? false,
    meta: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta || {},
    alias: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.alias || [],
    redirect: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.path ?? "/spa-balneario/malaga/benalmadena/",
    props: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.props ?? false,
    meta: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta || {},
    alias: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.alias || [],
    redirect: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.path ?? "/spa-balneario/malaga/estepona/",
    props: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.props ?? false,
    meta: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta || {},
    alias: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.alias || [],
    redirect: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.path ?? "/spa-balneario/malaga/fuengirola/",
    props: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.props ?? false,
    meta: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta || {},
    alias: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.alias || [],
    redirect: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.path ?? "/spa-balneario/malaga/malaga/",
    props: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.props ?? false,
    meta: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta || {},
    alias: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.alias || [],
    redirect: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.path ?? "/spa-balneario/malaga/marbella/",
    props: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.props ?? false,
    meta: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta || {},
    alias: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.alias || [],
    redirect: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.path ?? "/spa-balneario/malaga/mijas/",
    props: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.props ?? false,
    meta: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta || {},
    alias: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.alias || [],
    redirect: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.path ?? "/spa-balneario/malaga/ronda/",
    props: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.props ?? false,
    meta: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta || {},
    alias: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.alias || [],
    redirect: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.name ?? "Spa by location landing: 9",
    path: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.path ?? "/spa-balneario/malaga/torremolinos/",
    props: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.props ?? false,
    meta: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta || {},
    alias: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.alias || [],
    redirect: SpaList_46pageMnbpaFGtwnXeW7lnqRmvBanrStz9JsIxL79HoaLYOmYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/",
    props: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.props ?? false,
    meta: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta || {},
    alias: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.alias || [],
    redirect: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/",
    props: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.props ?? false,
    meta: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta || {},
    alias: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.alias || [],
    redirect: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/",
    props: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.props ?? false,
    meta: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta || {},
    alias: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.alias || [],
    redirect: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.path ?? "/spa-balneario/malaga/calma-spa/",
    props: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.props ?? false,
    meta: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta || {},
    alias: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.alias || [],
    redirect: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.name ?? "Spa landing: 4",
    path: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/",
    props: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.props ?? false,
    meta: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta || {},
    alias: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.alias || [],
    redirect: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.name ?? "Spa landing: 5",
    path: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/",
    props: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.props ?? false,
    meta: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta || {},
    alias: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.alias || [],
    redirect: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.name ?? "Spa landing: 6",
    path: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.path ?? "/spa-balneario/malaga/alum-spa-maestranza/",
    props: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.props ?? false,
    meta: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta || {},
    alias: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.alias || [],
    redirect: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.name ?? "Spa landing: 7",
    path: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/",
    props: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.props ?? false,
    meta: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta || {},
    alias: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.alias || [],
    redirect: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.name ?? "Spa landing: 8",
    path: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/",
    props: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.props ?? false,
    meta: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta || {},
    alias: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.alias || [],
    redirect: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.name ?? "Spa landing: 9",
    path: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.path ?? "/spa-balneario/malaga/hard-rock-spa-marbella/",
    props: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.props ?? false,
    meta: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta || {},
    alias: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.alias || [],
    redirect: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.name ?? "Spa landing: 10",
    path: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/",
    props: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.props ?? false,
    meta: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta || {},
    alias: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.alias || [],
    redirect: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.name ?? "Spa landing: 11",
    path: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.path ?? "/spa-balneario/malaga/hydros-spa/",
    props: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.props ?? false,
    meta: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta || {},
    alias: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.alias || [],
    redirect: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.name ?? "Spa landing: 12",
    path: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/",
    props: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.props ?? false,
    meta: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta || {},
    alias: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.alias || [],
    redirect: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.name ?? "Spa landing: 13",
    path: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/",
    props: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.props ?? false,
    meta: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta || {},
    alias: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.alias || [],
    redirect: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.name ?? "Spa landing: 14",
    path: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.path ?? "/spa-balneario/malaga/mood-spa/",
    props: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.props ?? false,
    meta: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta || {},
    alias: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.alias || [],
    redirect: SpaDetail_46paged5RMlu9nF3wuJ93DBdnKj9snJU_45j5v8GzB94luesyeAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-relajante-de-cabeza-cuello-y-hombros/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-aromatico-50-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/piel-de-seda/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/body-wrap/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/terapia-geotermal-con-piedras/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-relajante-de-pies/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/el-arte-de-crear/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-dinamico-de-recuperacion-corporal/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-terapeutico-50-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/tratamiento-de-limpieza-facial-purificante/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/peeling-corporal-con-cristales-marinos/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/ritual-anti-stress/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/baneras-de-inmersion-en-hielo/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-platinum-beach/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/tarjeta-regalo-importe-200e/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-banera-de-inmersion-en-hielo-masaje-deportivo-25-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-circuito-spa-con-cava-y-bombones/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/circuito-spa/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-presoterapia/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-banera-de-inmersion-en-hielo-spa/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-relax-unica-spa-masaje-relax-50/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-duo-80/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-banera-de-inmersion-en-hielo-masaje-deportivo-25-min-spa/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-duo-romantico/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-hidratacion-profunda-spa-tratamiento-facial-de-acido-hialuronico/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-relajante-aromatico-25-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-relajante-aromatico-50-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/facial-anti-aging-de-acido-hialuronico/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-piedras-calientes/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/aire-de-hadas/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/circuito-spa-ritual/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/circuito-spa-masaje-50/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/masaje-personalizado-50/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/circuito-spa-masaje-25/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/masaje-personalizado-25/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/passage-du-silence/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-90/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-60/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/calma-spa/facial-express/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/calma-spa/ritual-piel-de-seda/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-relajante-calma/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/calma-spa/ritual-detox/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-masaje-calma-para-2/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-masaje-calma/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-ritual-de-argan-para-2/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-ritual-de-argan/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-en-pareja/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-piedras-calientes/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-de-espalda/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-indio-de-cabeza/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-express/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/pack-ritual-parejas/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/desconexion-con-spa-y-belleza-para-2/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/bienestar-con-spa-y-ritual-para-2/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/piscina-climatizada/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/ritual-aires-del-mediterraneo/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/circuito-spa/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa-masaje-y-gastronomia/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/masaje-de-aromaterapia-50-minutos-y-circuito-spa/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/las-dunas-facial-deluxe-y-circuito-spa/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa-y-desayuno/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa-y-masaje/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/masaje-con-piedras-calientes-80-minutos-y-circuito-spa/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/vip-spa-suite/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa-ritual/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/aire-de-hadas/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/mountain-flower/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/masaje-tailandes/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa-masaje-50/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/masaje-personalizado-50/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa-masaje-25/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/masaje-personalizado-25/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/passage-du-silence/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/olive-flower-massage/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/viaje-a-la-ruta-de-las-delicias-circuito-en-pareja/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-hydraluronic/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/viaje-magico-de-la-ruta-de-la-seda/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/viaje-al-eden-tropical/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-1-adulto-2-ninos/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-2-adultos-1-nino/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-1-adulto-1-nino/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-deep-tisue/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-bienestar-masaje-relajante-50-en-pareja/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-romance-de-sol-y-luna-circuito-en-pareja/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-de-piedras-calientes/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/oasis-de-serenidad-circuito/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/sprunch-para-6-personas/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-y-corporal-geisha/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/masaje-con-piedras-calientes/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/masaje-ayurvedico-para-embarazadas/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/circuito-spa-privado-masaje-relajante-o-descontracturante/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-detox/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-con-vitamina-c/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-y-corporal-de-chocolaterapia/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-corporal-de-exfoliacion-e-hidratacion-tropical/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/circuito-spa-privado-desayuno/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/circuito-spa-privado/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-corporal-al-andalus/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/hydros-spa/circuito-de-aguas/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/hydros-spa/circuito-de-aguas-masaje/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/hydros-spa/circuito-de-luna-llena/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-premium/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-peeling/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/reflexologia-podal/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-pureza/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-sunset-in-egypt/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-relajante-80-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-relajante-50-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-al-eden-tropical/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-romance-de-sol-y-luna/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-a-la-provenza/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-deep-tisue-80-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-deep-tisue-50-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-capricho-de-los-dioses/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-esplendor-radiante/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-magico-de-la-ruta-de-la-seda/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-brisa-de-ganesha/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-a-las-vinas-de-la-toscana/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/energizer-40-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/detox-40-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/swedish-massage/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/deep-tissue/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/stone-therapy/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/foot-reflexology/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/booster-vitamin-c/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/essences-senses/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/energizer-55-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/detox-55-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/circuito-hydrowellness/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/mood-spa/indibar-legs-relief/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/mood-spa/relax-renew-massage-60-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/mood-spa/hot-stones/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/mood-spa/ayurvedic-shirodhara-abhyanga/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 137",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/mood-spa/peeling-massage-cocooning/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 138",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/mood-spa/indibar-corporal/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 139",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/mood-spa/signature-mediterranean-lifting-anti-aging/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 140",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/mood-spa/ayurvedic-pinda-herbal-therapy-60-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 141",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/mood-spa/precious-pearl/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 142",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/mood-spa/anti-stress-back-remedy/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 143",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/mood-spa/mediterranean-bliss-face-body/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 144",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/mood-spa/indibar-facial/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 145",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/mood-spa/candle-night/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 146",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/mood-spa/circuito-spa-2-horas/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.name ?? "Service landing: 147",
    path: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.path ?? "/spa-balneario/malaga/mood-spa/deep-tissue-60-min/",
    props: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.props ?? false,
    meta: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta || {},
    alias: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.alias || [],
    redirect: ServiceDetail_46pageSYhK4KKDSCw4_8wL5mMnS7JjooZX0gnrCPGTnv58mBAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-a41a47e8-4c9b-4633-a766-ef527095143f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]